import React from "react"

import Layout from "components/Layout/de"
import Image from "components/Image/index"
import Product from "components/Layout/Product/index"
import productImg from "./wood.mp4"
import productIcon from "./WoodScanner.svg"
import wood1 from "./wood1.png"
import { Table } from "react-bootstrap"
import { MdDone, MdCancel } from "react-icons/md"

import image1 from "./wood_inspection_0.jpg"
import image2 from "./product.jpg"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Bestimmen Sie präzise und automatisiert die Qualität von Holzstämmen
        noch bevor Sie diese verarbeiten. Die MoonVision Software nutzt visuelle
        Daten von Kameras um zu prüfen – ob ihre Qualitätsmerkmale erfüllt
        werden und welche Fläche für nachgelagerte Verwendungszwecke genutzt
        werden kann.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>
          Automatisierte Qualitätsprüfung von verarbeitetem und unverarbeitetem
          Holz
        </li>
        <li>
          Von gleichbleibender Qualität und verkürzter Durchlaufzeit profitieren
        </li>
        <li>Erkennung und Messung mittels Kamera & Software</li>
        <li>Höchste Genauigkeit & Komfort durch Künstliche Intelligenz</li>
        <li>Auswertungen an Folgesysteme oder visuelle Darstellung</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="WoodScanner"
        title="Qualitätsprüfung von Holz in Echtzeit"
        subtitle="Effiziente Software für die Analyse von Holzqualität - einfach zu
              bedienen und in Echtzeit"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automatisierte Holzprüfung",
          "Gleichbleibend hohe Qualität",
          "Verkürzte Durchlaufzeit",
          "Jetzt Informieren",
        ]}
        images={[image1, image2]}
        type="wood"
        technicalTable={
          <React.Fragment>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th colSpan="2">Erkennung der Güteklassen</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Geringerer Ressourcenaufwand</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Verkürzung der Taktzeit</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">
                    Beispielsetup Spezifikation Erkennung von Güteklassen
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>D (CX)</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Erkennung von Dimensionen</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Länge</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Breite</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Tiefe</td>
                  <td>
                    <MdCancel />
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">
                    Unterscheidung zwischen bestimmten Merkmalen
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Festgewachsener / fauler Ast</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Risse</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Länge</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Breite</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Tiefe</td>
                  <td>
                    <MdCancel />
                  </td>
                </tr>
                <tr>
                  <td>Abholzigkeit</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Drehwuchs</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Krummschäftigkeit</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Image filename={wood1} alt="Holz" />
          </React.Fragment>
        }
      >
        <React.Fragment>
          <p>
            Der MoonVision WoodScanner wurde speziell für die Oberflächenprüfung
            von Holz entwickelt. Dabei werden neueste Technologien wie Machine
            Learning eingesetzt, um auch die kleinsten, für Mitarbeiter kaum
            rasch zu erkennende Abweichungen festzustellen und das Holz auf
            dieser Basis zu klassifizieren. Im Anschluss können auch Messungen
            durchgeführt werden. Prüfungen stellen sicher dass das Holz
            geltenden Standards entspricht oder aber Sie definieren eigene
            Prüfregeln. Die neu generierten Informationen können zur
            Unterteilung genutzt werden um das Holz zB unterschiedlichen
            Verwendungszwecken zuzuführen oder Statistiken zu erstellen.
          </p>
          <p>
            Durch die eingesetzte Künstliche Intelligenz werden bestehende
            Systeme im Hinblick auf die Prüfgenauigkeit in den Schatten gestellt
            – der Bewertungsprozess wird vollautomatisiert und Sie sparen
            wertvolle Zeit.
          </p>
          <p>
            Die MoonVision Software kann sowohl bei Rohholz als auch
            Holzprodukten jeder Art eingesetzt werden. Damit können wir:
          </p>
          <ul>
            <li>Güteklassen automatisiert erkennen</li>
            <li>Oberflächenteile kategorisieren</li>
            <li>Defekte erkennen und und ihre Ausprägung exakt vermessen</li>
          </ul>
          <p>
            Der MoonVision WoodScanner kann an unterschiedlichen
            Produktionsschritten von der Wareneingangskontrolle bis hin zur
            Warenausgangskontrolle eingesetzt werden. Durch das autarke Design
            kann die Software in einen laufenden Fertigungsprozess bzw.
            vorhandene ERP-Systeme integriert werden.
          </p>
          <p>Nach Ablauf der Kontrolle können die Ergebnisse ausserdem:</p>
          <ul>
            <li>
              Visuell dargestellt werden (Auf einem Monitor/Alarme/Ablage von
              jpg Dateien)
            </li>
            <li>Tabellarisch zusammengefasst werden</li>
            <li>
              Per APIs an Managementinformationssysteme weitergegeben werden
            </li>
            <li>Per APIs zur Prozesssteuerung eingesetzt werden</li>
          </ul>
          <p>Durch das MoonVision Konzept</p>
          <ul>
            <li>
              können individuelle Anpassungen selbstständig durchgeführt werden
            </li>
            <li>
              bleibt der Zugang und Kontrolle über die Erkennungsparameter bei
              ihnen
            </li>
          </ul>
          <h3>Überall Einsetzbar</h3>
          <p>
            Der MoonVision WoodScanner kann alle bestehenden Kamerasysteme als
            Datenquellen nutzen – die den Genicam Standard erfüllen. Gerne
            beraten wir Sie auch bei der Wahl des richtigen Systems. Die
            Überprüfung kann in Echtzeit über unsere Online-Plattform erfolgen.
            Alternativ dazu können wir das System auch vor Ort installieren. In
            beiden Fällen erhalten Sie Zugriff zur MoonVision Toolbox und können
            damit jederzeit Anpassungen zB zur Parameteradaptierung und
            Personalisierung durchführen.
          </p>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Softwaredesigns ist die Optimierung und Implementierung
            des MoonVision Woodscanner mit geringem Aufwand vor Ort bei Ihnen
            realisierbar. Bereits nach der Überprüfung weniger Bild/Videodaten
            herrscht Gewissheit über die Qualität, wobei weitere Bilddaten auch
            zu einem späteren Zeitpunkt ergänzt werden können. Alle Daten werden
            zentral gesammelt, gespeichert und sind jederzeit für Sie abrufbar.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
